$(document).ready(function () {

  // imgLiquid init
  $(".img-fill").imgLiquid({
    fill: true,
    horizontalAlign: "center",
    verticalAlign: "center",
    maxWidth: 576
  });

  $(".img-no-fill").imgLiquid({
    fill: false,
    horizontalAlign: "center",
    verticalAlign: "center"
  });


  $('.phoneInput').mask('+7 (000) 000-00-00');

  $('.fancybox').fancybox();

  var mySwiper = new Swiper('.swiper-container', {
    speed: 400,
    spaceBetween: 100,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  // detect device

  let isMobile = navigator.userAgent.match(/(iPhone|iPod|iPad|BlackBerry|Android)/i) != null;
  let mouseDown = "mousedown";
  let mouseMove = "mousemove";
  let mouseUp = "mouseup";

  if (isMobile) {
    console.log('is mobile');
    mouseDown = "touchstart";
    mouseMove = "touchmove";
    mouseUp = "touchend";
  }

  let body = document.querySelector('body');

  body.addEventListener(mouseUp, function (e) {

    let menu = e.target.dataset.menu;

    if (!e.target.closest('.header-menu-wrapper') && !e.target.closest('.menu-btn')) {
      body.classList.remove('mobile-menu-opened');
    }

    if (menu) {
      if (menu === "toggle") {
        body.classList.toggle('mobile-menu-opened');
      }
      if (menu === "close") {
        body.classList.remove('mobile-menu-opened');
      }
    }

  });

  // $('#formRequestModal').modal();


  function commafy(num, sThousandsSeparator) {
    if (!sThousandsSeparator) {
      sThousandsSeparator = " ";
    }
    var bNegative = (num < 0);
    var sDecimalSeparator = ".";
    var sOutput;
    var nDotIndex;
    sOutput = num.toString();
    nDotIndex = sOutput.lastIndexOf(sDecimalSeparator);
    nDotIndex = (nDotIndex > -1) ? nDotIndex : sOutput.length;
    var sNewOutput = sOutput.substring(nDotIndex);
    var nCount = -1;
    for (var i = nDotIndex; i > 0; i--) {
      nCount++;
      if ((nCount % 3 === 0) && (i !== nDotIndex) && (!bNegative || (i > 1))) {
        sNewOutput = sThousandsSeparator + sNewOutput;
      }
      sNewOutput = sOutput.charAt(i - 1) + sNewOutput;
    }
    sOutput = sNewOutput;
    return sOutput;
  }


});